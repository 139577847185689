import HeaderDefault from '~/components/content-modules/HeaderDefault.vue';
import ContentText from '~/components/content-modules/ContentText.vue';
import ContentDownload from '~/components/content-modules/ContentDownload.vue';
import ContentBenefit from "~/components/content-modules/ContentBenefit.vue";
import ContentFigure from "~/components/content-modules/ContentFigure.vue";
import ContentFigureCarousel from "~/components/content-modules/ContentFigureCarousel.vue";
import ContentSlider from "~/components/content-modules/ContentSlider.vue";
import ContentTeam from "~/components/content-modules/ContentTeam.vue";
import ContentTalks from "~/components/content-modules/ContentTalks.vue";
import ContentReport from "~/components/content-modules/ContentReport.vue";
import ContentImage from "~/components/content-modules/ContentImage.vue";

export const ContentModules = {
    HeaderDefaultRecord: HeaderDefault,
    ContentBenefitRecord: ContentBenefit,
    ContentFigureRecord: ContentFigure,
    ContentFigureCarouselRecord: ContentFigureCarousel,
    ContentSliderRecord: ContentSlider,
    ContentTextRecord: ContentText,
    ContentDownloadRecord: ContentDownload,
    ContentTeamRecord: ContentTeam,
    ContentTalkRecord: ContentTalks,
    ContentReportRecord: ContentReport,
    ContentImageRecord: ContentImage,
};
