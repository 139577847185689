<template>
  <div
      class="content-carousel"
      ref="carouselElement"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="js">
const { $Flickity } = useNuxtApp();
const props = defineProps();

const carouselElement = ref(null);

onMounted(() => {
  if ($Flickity && carouselElement.value) {
    const slider = new $Flickity(carouselElement.value, {
      accessibility: true,
      adaptiveHeight: false,
      autoPlay: false,
      cellAlign: 'center',
      cellSelector: undefined,
      contain: false,
      draggable: '>1',
      dragThreshold: 3,
      freeScroll: false,
      selectedAttraction: 0.18,
      friction: 0.9,
      groupCells: true,
      initialIndex: 0,
      lazyLoad: true,
      percentPosition: true,
      prevNextButtons: false,
      pageDots: false,
      resize: true,
      rightToLeft: false,
      setGallerySize: true,
      watchCSS: true,
      wrapAround: true
    });

    const images = carouselElement.value.querySelectorAll('.carousel-cell .parallax-image');

    if (images.length > 0) {
      slider.on('scroll', () => {
        slider.slides.forEach(function (slide, i) {
          const image = images[i].querySelector('.image');

          const x = (slide.target + slider.x) * -1/3;

          // image.style.display = 'none';
          image.style.transform = `translateX(${x}px)`;
        });
      });
    }

    slider.on( 'staticClick', (event, pointer, cellElement, cellIndex) => {
      if (!cellElement) {
        return;
      }

      if (cellElement.classList.contains('is-selected')) {
        return;
      }

      const isNarrow = event.target.closest('.-narrow');
      let newIndex = cellIndex;

      if (isNarrow && window.innerWidth >= 900) {
        newIndex = cellIndex / 2;
      }

      slider.select(newIndex);
    });

    // TODO: The following lines are only very very quicky quicky fixy fixy, refactor asap!
    setTimeout(() => {
      slider.resize();
    }, 300);

    setTimeout(() => {
      slider.resize();
    }, 800);

    setTimeout(() => {
      slider.resize();
    }, 2000);
  }
});
</script>

<style lang="scss">
.content-carousel {
  &::after {
    content: 'flickity';
    display: none;

    // ℹ️ To disable flickity simply create an emtpy after element:
    //    content: '';
  }

  &.flickity-enabled {
    @media (--sm) {
      margin-inline: -1.562rem;
    }

    @media (--md-lg) {
      margin-inline: -4.125rem;
    }

    @media (--xl) {
      margin-inline: -8.562rem;
    }

    .carousel-cell {
      @media (--sm) {
        width: calc(100% - 3.124rem);
        margin-right: 0.625rem;
      }

      @media (--md-lg) {
        width: calc(100% - 8.25rem);
        margin-right: 1.562rem;
      }

      @media (--xl) {
        width: calc(100% - 17.124rem);
        margin-right: 3.938rem;
      }

      &.-narrow {
        @media screen and (min-width: 900px) {
          width: calc((100% - 8.25rem - 1.562rem) / 2);
          margin-right: 1.562rem;
        }

        @media (--xl) {
          width: calc((100% - 17.124rem - 3.938rem) / 2);
          margin-right: 3.938rem;
        }
      }
    }
  }
}

.parallax-image {
  overflow: hidden;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--lg-down) {
    border-radius: var(--base-border-radius);
  }

  @media (--lg-up) {
    border-top-left-radius: var(--base-border-radius);
    border-bottom-left-radius: var(--base-border-radius);
  }

  > * {
    height: 100%;
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
    aspect-ratio: 1000 / 680;
  }
}
</style>
