<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core';
import { Image, StructuredText } from 'vue-datocms';
import renderBlock from '~/datocms/renderBlock';
import type { LottiePlayer } from 'lottie-web';
import { customMarkRules } from '~/utils/customMarkRules';

const props = defineProps<{
  data: {
    asset: {
      url: string;
      format: string;
      responsiveImage: object;
    };
    figure: string;
    copy: string;
  };
}>();

const lottieAnimation = ref<null | LottiePlayer>(null);
const cardElement = ref<null | HTMLElement>(null);
const isCardVisible = ref<boolean>(false);

const { stop } = useIntersectionObserver(cardElement, ([{ isIntersecting }], observerElement) => {
  isCardVisible.value = isIntersecting;

  if (isIntersecting) {
    lottieAnimation.value?.play();

    stop();
  }
});
</script>

<template>
  <figure
    class="content-figure-card"
    :class="{ '-has-image': data.asset, '-once-in-viewport': isCardVisible }"
    ref="cardElement"
  >
    <div class="animation" v-if="data.asset">
      <client-only v-if="data.asset.format === 'json'">
        <Vue3Lottie
          ref="lottieAnimation"
          class="image"
          :animationLink="data.asset.url"
          :auto-play="false"
          :loop="1"
        />
      </client-only>
      <Image
        class="image"
        v-else-if="data.asset.responsiveImage"
        :data="data.asset.responsiveImage"
      />
      <img class="image" v-else-if="data.asset.url" :src="data.asset.url" alt="" />
    </div>
    <h3 class="title">
      <div class="figure" v-html="data.figure"></div>
      <div class="copy">
        <StructuredText
          class="role"
          v-if="data.copy"
          :data="data.copy"
          :render-block="renderBlock"
          :custom-mark-rules="customMarkRules"
        />
      </div>
    </h3>
  </figure>
</template>

<style scoped lang="scss">
.content-figure-card {
  display: flex;
  flex-direction: column;
  background-color: var(--base-color-background);
  border-radius: var(--base-border-radius);
  color: var(--base-color-brand);
  min-height: 100%;

  @media (--sm) {
    padding: 1rem 2.375rem;
  }

  @media (--md-up) {
    padding: 2rem 2.375rem;
  }

  &.-has-image {
    align-items: center;
    text-align: center;

    @media (--sm) {
      padding: 0.75rem 2.375rem 2.5rem;
    }

    @media (--md-up) {
      padding: 0.75rem 1rem 4rem;
    }
  }

  > .animation {
    aspect-ratio: 1 / 1;
    width: 100%;
  }

  > .title {
    @include text-base;

    > .figure {
      @include text-base(bold);

      margin-bottom: 0.25em;

      @media (--sm) {
        font-size: 2.625rem;
      }

      @media (--md-lg) {
        font-size: 2.5rem;
      }

      @media (--xl) {
        font-size: 2.25rem;
      }
    }
  }
}
</style>
