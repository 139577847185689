<script setup lang="ts">
import { Image, StructuredText } from 'vue-datocms';
import renderBlock from '~/datocms/renderBlock';
import type { LottiePlayer } from 'lottie-web';
import { useIntersectionObserver } from '@vueuse/core';
import { customMarkRules } from '~/utils/customMarkRules';

const props = defineProps<{
  data: {
    asset: {
      url: string;
      responsiveImage: object;
      format: string;
    };
    figure: string;
    copy: string;
  };
}>();

const lottieAnimation = ref<null | LottiePlayer>(null);
const cardElement = ref<null | HTMLElement>(null);
const isCardVisible = ref<boolean>(false);

const { stop } = useIntersectionObserver(cardElement, ([{ isIntersecting }], observerElement) => {
  isCardVisible.value = isIntersecting;

  if (isIntersecting) {
    lottieAnimation.value?.play();

    stop();
  }
});
</script>

<template>
  <figure ref="cardElement" class="content-figure-carousel-card">
    <div class="animation" v-if="data.asset">
      <client-only v-if="data.asset.format === 'json'">
        <Vue3Lottie
          ref="lottieAnimation"
          class="image"
          :animationLink="data.asset.url"
          :auto-play="false"
          :loop="1"
        />
      </client-only>
      <Image class="image" v-else :data="data.asset.responsiveImage" />
    </div>
    <div class="content">
      <h3 class="title">
        {{ data.figure }}
      </h3>
      <StructuredText
        class="copy"
        v-if="data.copy"
        :data="data.copy"
        :render-block="renderBlock"
        :custom-mark-rules="customMarkRules"
      />
    </div>
  </figure>
</template>

<style scoped lang="scss">
.content-figure-carousel-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--base-color-brand);
  border-radius: var(--base-border-radius);
  color: var(--base-color-background);

  @media (--lg-down) {
    padding: 2.125rem 0.188rem 2.5rem;
  }

  @media (--lg-up) {
    padding: 2.75rem 2.375rem 5.188rem;
    aspect-ratio: 636 / 650;
    width: 100%;
  }

  > .animation {
    @media (--lg-up) {
      margin-inline: auto;
      width: 100%;
    }

    > .image {
      aspect-ratio: 511 / 342;
    }

    + .content {
      margin-top: 0.25rem;
    }
  }

  > .content {
    border-top: 1px solid var(--base-color-background);

    @media (--lg-down) {
      margin-inline: 1.688rem;
      padding-top: 1rem;
    }

    @media (--lg-up) {
      width: 100%;
      padding-top: 2.75rem;
    }

    > .title {
      @include text-base(bold);

      @media (--lg) {
        @include text-heading-small(bold);
      }

      + .copy {
        @media (--lg-down) {
          margin-top: 0.688rem;
        }

        @media (--lg-up) {
          margin-top: 1.688rem;
        }
      }
    }

    > .copy {
      @include text-base;

      @media (--lg) {
        @include text-lead(light);
      }
    }
  }
}
</style>
