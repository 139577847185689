<script setup lang="ts">
// import { StructuredText } from 'vue-datocms';
// import type {DatoResponsiveImage} from "~/datocms/datocms.types";
// import ResponsiveImage from "~/components/ResponsiveImage.vue";

import {Image} from "vue-datocms";

const props = defineProps<{
  data: {
    id: string;
    title: string;
    link: object;
    document: object;
  }
}>()

const documents = computed(function(){
  return props.data.document ?? []
})
</script>

<template>
  <div class="content-download">
    <div
      class="intro"
      v-if="data.title"
    >
      <h2 class="title module-title">
        {{ data.title }}
      </h2>
      <a
          class="link"
          :href="data.link.slug"
          v-if="data.link"
      >
        {{ data.link.title }}
      </a>
    </div>
    <div
        class="overview"
        data-in-viewport
    >
      <div
          class="item"
          v-for="(d, index) in documents"
      >
        <a
            class="link"
            :href="d.document.url"
            :class="{'-no-image': !d.thumbnail}"
            target="_blank"
            v-if="d.__typename === 'DocumentRecord'"
        >
          <div
              class="imagewrapper"
              v-if="d.thumbnail"
          >
            <Image
                class="image"
                :data="d.thumbnail.responsiveImage"
            />
          </div>
          <span class="label">
            <span class="text">
              {{ d.documentTitle }}
            </span>
          </span>
        </a>
        <a
          class="link -no-image -link"
          v-else-if="d.__typename === 'DownloadsLinkRecord' && d.externalLink"
          :href="d.externalLink"
          target="_blank"
        >
          <span class="label">
            <span class="text">
              {{ d.title }}
            </span>
          </span>
        </a>
        <NuxtLink
          class="link -no-image -link"
          v-else-if="d.__typename === 'DownloadsLinkRecord' && d.link.slug"
          :to="d.link.slug"
        >
          <span class="label">
            <span class="text">
              {{ d.title }}
            </span>
          </span>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.content-download {
  > .intro {
    @media (--md-up) {
      display: grid;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
    }

    > .link {
      @include text-base-small;

      text-decoration: none;

      @media (--md-up) {
        margin-left: auto;
      }

      @media (hover: hover) {
        &:hover::before {
          transform: translateX(0.5rem);
        }
      }

      &::before {
        display: inline-block;
        content: "";
        width: 0.75em;
        height: 0.75em;
        background: var(--base-color-brand);
        margin-right: 0.75em;
        mask-image: url("/arrow-link.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        transition: var(--base-transition-all);
      }
    }
  }

  > .intro + .overview {
    @media (--lg-down) {
      margin-top: 2.5rem;
    }

    @media (--lg) {
      margin-top: 1.938rem;
    }

    @media (--xl) {
      margin-top: 3.5rem;
    }
  }

  > .overview {
    display: grid;

    @media (--lg) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2.625rem 1rem;
    }

    @media (--xl) {
      grid-template-columns: repeat(3, 1fr);
      gap: 2.625rem;
    }
  }

  > .overview > .item > .link {
    display: block;
    text-decoration: none;

    @media (--lg-down) {
      margin-bottom: 2.25rem;
    }

    &.-no-image {
      padding-top: 1rem;
      border-top: 1px solid #D5ECE9;

      @media (--lg-down) {
        margin-bottom: 1rem;
      }
    }

    &:not(.-link) {
      @media (hover: hover) {
        &:hover {
          > .label > .text {
            transform: translateX(0.2em);
          }

          > .label::before {
            transform: translateY(0.2em);
          }
        }
      }

      > .label::before,
      > .label::after {
        display: inline-block;
        position: absolute;
        left: 0;
        content: "";
        width: 0.75em;
        height: 0.75em;
        background: var(--base-color-brand);
        margin-right: 0.75em;
        mask-size: contain;
        mask-repeat: no-repeat;
      }

      > .label::before {
        top: 0.2em;
        mask-image: url("/arrow-download-top.svg");
        transition: transform var(--base-transition-duration) var(--base-transition-easing);
      }

      > .label::after {
        top: 0.35em;
        mask-image: url("/arrow-download-bottom.svg");
      }
    }

    &.-link {
      @media (hover: hover) {
        &:hover {
          > .label > .text {
            transform: translateX(0.2em);
          }

          > .label::before {
            transform: translateX(0.3em);
          }
        }
      }

      > .label::before {
        display: inline-block;
        position: absolute;
        left: 0;
        content: "";
        width: 0.75em;
        height: 0.75em;
        background: var(--base-color-brand);
        margin-right: 0.75em;
        mask-size: contain;
        mask-repeat: no-repeat;
      }

      > .label::before {
        top: 0.4em;
        mask-image: url("/arrow-link.svg");
        transition: transform var(--base-transition-duration) var(--base-transition-easing);
      }
    }

    @media (hover: hover) {
      &:hover {
        > .imagewrapper > .image {
          scale: 1.025;
        }
      }
    }

    > .imagewrapper {
      margin-bottom: 1.375rem;
      aspect-ratio: 1.4137931;
      border-radius: var(--base-border-radius);
      overflow: hidden;

      > .image {
        aspect-ratio: 1.4137931;
        transition: scale var(--base-transition-duration) var(--base-transition-easing);
      }
    }

    > .label {
      @include text-base-small;

      position: relative;

      > .text {
        display: inline-block;
        padding-left: 1.25em;
        transition: transform var(--base-transition-duration) var(--base-transition-easing);
      }
    }
  }
}
</style>
