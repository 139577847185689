<template>
  <div class="content-talks">
    <div class="intro content-talks-intro">
      <video
        v-if="videoLargeScreens"
        class="video -large"
        autoplay
        muted
        loop
        playsinline
        :width="videoLargeScreens.width"
        :height="videoLargeScreens.height"
      >
        <source :src="videoLargeScreens.url" type="video/mp4" />

        Download the <a :href="videoLargeScreens.url">MP4</a> video.
      </video>
      <video
        v-if="videoSmallScreens"
        class="video -small"
        autoplay
        muted
        loop
        playsinline
        :width="videoSmallScreens.width"
        :height="videoSmallScreens.height"
      >
        <source :src="videoSmallScreens.url" type="video/mp4" />

        Download the <a :href="videoSmallScreens.url">MP4</a> video.
      </video>
      <div class="text">
        <h2 class="title" v-if="data.title">
          {{ data.title }}
        </h2>
        <p class="copy" v-if="data.copy">
          {{ data.copy }}
        </p>
      </div>
    </div>

    <div class="overview content-talks-overview">
      <div class="item content-talks-item" v-for="(talk, index) in data.talks" :key="talk.id">
        <a
          class="link"
          :href="talk.link ?? '#'"
          :class="{ '-disabled': !talk.link }"
          @click.prevent="openPodcast(talk.link)"
          title="Play Podcast"
        >
          <div class="imagewrapper" v-if="talk.asset">
            <Image class="image" :data="talk.asset.responsiveImage" />
            <!--            <img class="branding" src="/logo-swica-talks.svg" aria-hidden="true" />-->
          </div>
          <div class="podcast">
            <div class="icon" v-if="talk.link"></div>
            <span class="date">{{ talk.date }}</span>
            <span class="duration" v-if="talk.duration">{{ talk.duration }}</span>
          </div>
        </a>
        <h3 class="title">{{ index + 1 }} · {{ talk.title }}</h3>
        <StructuredText
          :data="talk.copy"
          v-if="talk.copy"
          :render-block="renderBlock"
          :custom-mark-rules="customMarkRules"
        ></StructuredText>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Image, StructuredText } from 'vue-datocms';
import type { DatoResponsiveImage } from '~/datocms/datocms.types';
import renderBlock from '~/datocms/renderBlock';
import { customMarkRules } from '~/utils/customMarkRules';

const props = defineProps<{
  data: {
    title: string;
    copy: string;
    keyVideoLargeScreens: object;
    keyVideoSmallScreens: object;
    talks: {
      id: string;
      date: string;
      duration: string;
      title: string;
      copy: string;
      copy2: {
        value: object;
      };
      link: string;
      asset: DatoResponsiveImage;
    }[];
  };
}>();

const videoLargeScreens = computed(function () {
  if (!props.data.keyVideoLargeScreens) {
    return null;
  }

  return {
    width: props.data.keyVideoLargeScreens.width ?? 100,
    height: props.data.keyVideoLargeScreens.height ?? 100,
    url: props.data.keyVideoLargeScreens.video?.mp4Url ?? props.data.keyVideoLargeScreens.url
  };
});

const videoSmallScreens = computed(function () {
  if (!props.data.keyVideoSmallScreens) {
    return null;
  }

  return {
    width: props.data.keyVideoSmallScreens.width ?? 100,
    height: props.data.keyVideoSmallScreens.height ?? 100,
    url: props.data.keyVideoSmallScreens.video?.mp4Url ?? props.data.keyVideoSmallScreens.url
  };
});

function openPodcast(link: string) {
  window.open(
    link,
    'targetWindow',
    `toolbar=no,
            location=no,
            status=no,
            menubar=no,
            scrollbars=yes,
            resizable=yes,
            width=580,
            height=153`
  );
}
</script>

<style lang="scss">
.content-talks {
  //> .intro + .overview {
  //  margin-top: 6rem;
  //}
}

.content-talks-intro {
  position: relative;

  @media (--sm) {
    margin-top: -2.5rem;
    margin-left: -1.562rem;
    margin-right: -1.562rem;
  }

  @media (--md-lg) {
    margin-top: -3.75rem;
    margin-left: -4.125rem;
    margin-right: -4.125rem;
  }

  @media (--xl) {
    margin-top: -4.625rem;
    margin-left: -8.562rem;
    margin-right: -8.562rem;
  }

  > .video {
    width: 100%;
    height: auto;
    border-top-left-radius: var(--base-border-radius);
    border-top-right-radius: var(--base-border-radius);

    @media (--sm) {
      margin-bottom: 2.25rem;
    }

    @media (--md) {
      margin-bottom: 4rem;
    }

    @media (--lg) {
      margin-bottom: 1.875rem;
    }

    @media (--xl) {
      margin-bottom: 2.812rem;
    }

    &.-small {
      @media (--md-up) {
        display: none;
      }
    }

    &.-large {
      @media (--sm) {
        display: none;
      }
    }
  }

  > .text {
    color: var(--color-white);

    @media (--sm) {
      padding-inline: 1.562rem;
      margin-bottom: 2.5rem;
    }

    @media (--md) {
      padding-inline: 4.125rem;
      margin-bottom: 2.25rem;
    }

    @media (--lg) {
      width: 33.333%;
      position: absolute;
      top: 2.25rem;
      left: 4.125rem;
    }

    @media (--xl) {
      width: 33.333%;
      position: absolute;
      top: 4.625rem;
      left: 8.562rem;
    }

    > .title {
      @media (--lg-up) {
        @include text-heading-small;
      }

      + .copy {
        @media (--sm) {
          @include font-weight(bold);

          margin-top: 1.375rem;
        }

        @media (--md-up) {
          margin-top: 1.812rem;
        }
      }
    }

    > .copy {
      @media (--lg-up) {
        @include font-weight(bold);
      }
    }
  }
}

.content-talks-overview {
  display: grid;

  @media (--sm) {
    gap: 2.25rem;
  }

  @media (--lg-up) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--md) {
    gap: 4rem;
  }

  @media (--lg) {
    gap: 1.875rem;
  }

  @media (--xl) {
    gap: 2.812rem;
  }
}

.content-talks-item {
  color: var(--color-white);

  > .link {
    text-decoration: none;
    color: inherit;

    &.-disabled {
      pointer-events: none;
    }

    @media (hover: hover) {
      > .imagewrapper img,
      > .podcast > .icon {
        transition: var(--base-transition-all) !important;
      }

      > .podcast > .date,
      > .podcast > .duration {
        transition: transform var(--base-transition-duration) var(--base-transition-easing);
      }

      &:hover {
        > .imagewrapper img {
          scale: 1.025;
        }

        > .podcast > .icon {
          background-color: #179e95;

          &::before {
            background-color: var(--color-white);
          }
        }

        > .podcast > .date,
        > .podcast > .duration {
          transform: translateX(0.2em);
        }
      }
    }
  }

  > .link > .imagewrapper {
    position: relative;

    > .image {
      border-radius: var(--base-border-radius);
      aspect-ratio: 638 / 836;

      img {
        border-radius: var(--base-border-radius);
      }
    }

    > .branding {
      width: 37.6%;
      position: absolute;
      top: 1.812rem;
      right: 1.625rem;
    }
  }

  > .link > .podcast {
    @include text-extra-small;

    display: flex;
    align-items: center;
    border-bottom: 1px solid #88b8b4;
    min-height: 4.25rem;

    @media (--sm) {
      @include text-base;

      padding-block: 0.875rem;
    }

    @media (--md-lg) {
      padding-block: 1rem;
    }

    @media (--xl) {
      padding-top: 1.188rem;
      padding-bottom: 1rem;
    }

    > .icon {
      position: relative;
      width: 2.25rem;
      height: 2.25rem;
      background-color: var(--color-white);
      margin-right: 1rem;
      border-radius: 50%;

      &::before {
        display: block;
        content: '';
        position: absolute;
        inset: 0;
        mask-image: url('/podcast-icon.svg');
        mask-size: contain;
        background-color: #179f96;
      }
    }

    > .date + .duration {
      margin-left: 0.75rem;
    }
  }

  > .title {
    @include text-base-large;

    margin-top: 1.375rem;
  }

  > .copy {
    @media (--sm) {
      @include text-base;
    }

    @media (--md-up) {
      @include text-base-small;
    }

    margin-top: 1.375rem;
  }
}
</style>
