export function useContainerIntersections(elements) {
    // elements = ref<HTMLElements[]>

    const elementsWithDataAttr = ref([])

    onMounted(function () {
        elementsWithDataAttr.value = Array.from(document.querySelectorAll('[data-in-viewport]'))

        function handleIntersection(entries) {
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('-was-in-viewport');
                    entry.target.classList.add('-is-in-viewport');
                } else {
                    entry.target.classList.remove('-is-in-viewport');
                }

                return this;
            });
        }

        const observer = new IntersectionObserver(handleIntersection, {
            rootMargin: '-50% 0px -10% 0px',
        });

        elements.value.forEach((container) => {
            observer.observe(container);
        });

        elementsWithDataAttr.value.forEach((container) => {
            observer.observe(container);
        });
    });
}
