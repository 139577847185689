import Text from "~/components/partials/Text.vue";
import Table from "~/components/partials/Table.vue";
import Quote from "~/components/partials/Quote.vue";
import Signature from "~/components/partials/Signature.vue";
import Figure from "~/components/partials/Figure.vue";
import CTACarer from "~/components/partials/CTACarer.vue";

export const Partials = {
    TextRecord: Text,
    TableRecord: Table,
    QuoteRecord: Quote,
    SignatureRecord: Signature,
    FigureRecord: Figure,
    CarerCtaRecord: CTACarer,
};
