<script setup lang="ts">
import ContentFigureCard from '~/components/ContentFigureCard.vue';
import type { DatoResponsiveImage } from '~/datocms/datocms.types';

const props = defineProps<{
  data: {
    title: string;
    figures: {
      id: string;
      title: string;
      asset: DatoResponsiveImage;
    }[];
  };
}>();

onUpdated(() => {
  matchHeights();
});

onUpdated(() => {
  matchHeights();
});

const matchHeights = () => {
  const allItems = [
    ...document.querySelectorAll('.content-figures > .overview > .item .content-figure-card')
  ];
  let tallestItem = 0;
  let itemHeight;

  allItems.forEach(($item) => {
    itemHeight = $item.offsetHeight;
    tallestItem = itemHeight > tallestItem ? itemHeight : tallestItem;
  });

  allItems.forEach(($item) => {
    $item.style.height = tallestItem + 'px';
  });
};
</script>

<template>
  <div class="content-figures">
    <h2 class="title module-title -centered">
      {{ data.title }}
    </h2>
    <div class="overview">
      <div class="item" v-for="figure in data.figures" :key="figure.id">
        <ContentFigureCard :data="figure" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-figures {
  > .title + .overview {
    @media (--sm) {
      margin-top: 2.188rem;
    }

    @media (--md-up) {
      margin-top: 4.188rem;
    }
  }

  > .overview {
    display: grid;

    @media (--sm) {
      gap: 1.25rem;
    }

    @media (--md-lg) {
      gap: 1.625rem;

      @media (min-width: 880px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (--xl) {
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
    }
  }
}
</style>
